<template>  
  <section>
    <b-overlay :show="loading" rounded="sm">
   
        <button
          :disabled="checkDisabled"
          v-on:click="loginSubmit"
          class="btn btn-fill mt-2 btn-block btn-primary"
        >
          {{ $t("LOGIN") }}
        </button>
        
    </b-overlay>

  </section>
</template>
<script>
export default {
  props: ["loginInfo"],
  data() {
    return {
      loading: true,
      auth_uuid: "",
      errorMessage: ""
    };
  },
  methods: {
    loginSubmit: async function(event) {
      event.preventDefault();
      this.loading = true;
      let self = this;
      self.$emit("loading",true);
      await this.$store.dispatch("errors/removeValidations");
      this.$http
        .post(this.user.hostname + "/authenticate", {
          email: this.loginInfo.email,
          auth_type: this.loginInfo.auth_type,
          auth_method: this.loginInfo.auth_method,
          client: "web"
        })
        .then(function(result) {
          self.auth_uuid = result.data.auth_uuid;
          self.makeRequest(result.data.request_url);
        })
        .catch(function() {
          self.$emit("loading",false);
          self.loading = false;
        });
    },
    makeRequest: function(url) {
      this.loading = true;
      let self = this;
      this.$http
        .get(url)
        .then(function() {
          self.verifyAuthentication();
        })
        .catch(function() {
          self.$emit("loading",false);
          self.loading = false;
        });
    },
    verifyAuthentication: function() {
      let self = this;
      this.$http
        .get(this.user.hostname + "/authenticate/result/" + this.auth_uuid)
        .then(function(result) {
          self.$emit('authenticated', result.data.token);
        })
        .catch(function() {
          self.loading = false;
          self.$emit("loading",false);
        });
    }
  },
  computed: {    
    checkDisabled: function() {
      return this.loginInfo.email == "";
    }
  },
  mounted: function() {
    this.loading = false;
    this.loginInfo.auth_method = "";
  }
};
</script>
<style></style>
