<template>
  <section>
    <b-overlay :show="loading" rounded="sm">
      <form @submit.prevent>
        <div class="form-group">
          <label class="col-form-label">{{ $t("PASSWORD") }}</label>
          <input
            class="form-control inputTransparent"
            v-model="password"
            v-on:keyup.enter="loginSubmit"
            autocomplete="current-password"
            type="password"
            name="password"
            required
          />
        </div>
      </form>
      <button
        :disabled="checkDisabled"
        v-on:click="loginSubmit"
        class="btn mt-2 btn-fill btn-block btn-primary"
      >
        {{ $t("LOGIN") }}
      </button>
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["loginInfo"],
  data() {
    return {
      loading: true,
      password: "",
      errorMessage: "",
    };
  },
  methods: {
    loginSubmit: async function(event) {
      event.preventDefault();      
      this.$emit("loading", true);
      this.loading = true;
      let self = this;
      await this.$store.dispatch("errors/removeValidations");
      this.$http
        .post(this.user.hostname + "/authenticate", {
          email: this.loginInfo.email,
          auth_type: this.loginInfo.auth_type,
          auth_method: this.loginInfo.auth_method,
          password: this.password.trim(),
          client: "web"
        })
        .then(function(result) {
          self.$emit("authenticated", result.data.token);
        })
        .catch(function() {
          self.$emit("loading", false);
          self.loading = false;
        });
    },
  },
  computed: {
    checkDisabled: function() {
      return this.loginInfo.email == "" || this.password == "";
    },
  },
  mounted: function() {
    this.loginInfo.auth_method = "";
    this.loading = false;
  },
};
</script>
<style></style>
