var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.user.authenticated
    ? _c("section", [
        _vm.show_logo
          ? _c(
              "div",
              { staticClass: "text-center mb-2" },
              [
                _c("b-img", {
                  staticClass: "login-logo",
                  attrs: {
                    fluid: "",
                    src: _vm.logoUrl,
                    alt: _vm.$t("ORGANISATION.TITLE") + " " + _vm.$t("NAME"),
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.methods && !_vm.checkAuthentication
          ? _c(
              "div",
              { staticClass: "box-content" },
              [
                !_vm.loading
                  ? _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "col-form-label" }, [
                        _vm._v(_vm._s(_vm.$t("EMAIL"))),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.loginInfo.email,
                            expression: "loginInfo.email",
                          },
                        ],
                        staticClass: "form-control inputTransparent",
                        attrs: {
                          id: "login_email",
                          autocomplete: "username",
                          type: "text",
                          name: "email",
                          required: "",
                        },
                        domProps: { value: _vm.loginInfo.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.loginInfo,
                              "email",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                !_vm.loading
                  ? _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { staticClass: "col-form-label" }, [
                          _vm._v(_vm._s(_vm.$t("AUTH_METHODS.SELECT_METHOD"))),
                        ]),
                        _c("b-form-select", {
                          attrs: {
                            name: "auth_type",
                            id: "auth_options",
                            options: _vm.options,
                          },
                          model: {
                            value: _vm.selectedIndex,
                            callback: function ($$v) {
                              _vm.selectedIndex = $$v
                            },
                            expression: "selectedIndex",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.currentSelectHasChilds
                  ? _c("b-form-select", {
                      staticClass: "mb-2 mt-2",
                      attrs: {
                        name: "auth_method",
                        id: "auth_method",
                        options: _vm.childs_options,
                      },
                      model: {
                        value: _vm.selectedChildIndex,
                        callback: function ($$v) {
                          _vm.selectedChildIndex = $$v
                        },
                        expression: "selectedChildIndex",
                      },
                    })
                  : _vm._e(),
                _vm.loginInfo.auth_type == "password"
                  ? _c("AuthPassword", {
                      attrs: { loginInfo: _vm.loginInfo },
                      on: {
                        authenticated: _vm.authenticated,
                        loading: _vm.setLoading,
                      },
                    })
                  : _vm._e(),
                _vm.loginInfo.auth_type == "password-sms"
                  ? _c("AuthPasswordSms", {
                      attrs: { loginInfo: _vm.loginInfo },
                      on: {
                        authenticated: _vm.authenticated,
                        loading: _vm.setLoading,
                      },
                    })
                  : _vm._e(),
                _vm.loginInfo.auth_type == "sefos-certificate"
                  ? _c("AuthSefosCertificate", {
                      attrs: { loginInfo: _vm.loginInfo },
                      on: {
                        authenticated: _vm.authenticated,
                        loading: _vm.setLoading,
                      },
                    })
                  : _vm._e(),
                _vm.loginInfo.auth_method == "bankid-se"
                  ? _c("AuthBankid", {
                      attrs: { loginInfo: _vm.loginInfo },
                      on: {
                        authenticated: _vm.authenticated,
                        loading: _vm.setLoading,
                        "update:loginInfo": function ($event) {
                          _vm.loginInfo = $event
                        },
                        "update:login-info": function ($event) {
                          _vm.loginInfo = $event
                        },
                      },
                    })
                  : _vm._e(),
                _vm.loginInfo.auth_method == "freja" ||
                _vm.loginInfo.auth_type == "freja-org"
                  ? _c("AuthFreja", {
                      attrs: { loginInfo: _vm.loginInfo },
                      on: {
                        authenticated: _vm.authenticated,
                        loading: _vm.setLoading,
                        "update:loginInfo": function ($event) {
                          _vm.loginInfo = $event
                        },
                        "update:login-info": function ($event) {
                          _vm.loginInfo = $event
                        },
                      },
                    })
                  : _vm._e(),
                _vm.loginInfo.auth_method == "net-id-access"
                  ? _c("AuthNetIDAccess", {
                      attrs: { loginInfo: _vm.loginInfo },
                      on: {
                        authenticated: _vm.authenticated,
                        loading: _vm.setLoading,
                      },
                    })
                  : _vm._e(),
                _vm.loginInfo.auth_method == "net-id-enterprise"
                  ? _c("AuthNetIDEnterprise", {
                      attrs: { loginInfo: _vm.loginInfo },
                      on: {
                        authenticated: _vm.authenticated,
                        loading: _vm.setLoading,
                        "update:loginInfo": function ($event) {
                          _vm.loginInfo = $event
                        },
                        "update:login-info": function ($event) {
                          _vm.loginInfo = $event
                        },
                      },
                    })
                  : _vm._e(),
                _vm.showCustom
                  ? _c("AuthCustom", {
                      attrs: { methods: _vm.methods, loginInfo: _vm.loginInfo },
                      on: {
                        authenticated: _vm.authenticated,
                        loading: _vm.setLoading,
                        "update:loginInfo": function ($event) {
                          _vm.loginInfo = $event
                        },
                        "update:login-info": function ($event) {
                          _vm.loginInfo = $event
                        },
                      },
                    })
                  : _vm._e(),
                _vm.currentSelectHasChilds
                  ? _c("div", [
                      _vm.showChildrenSubmitDisabled
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-fill btn-block mt-2 btn-primary",
                              attrs: { disabled: true },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("NEXT")) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.show_logo
          ? _c("div", { staticClass: "text-center mt-4" }, [_vm._v(" ")])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }