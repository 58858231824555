var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
        _c(
          "button",
          {
            staticClass: "btn btn-fill mt-2 btn-block btn-primary",
            attrs: { disabled: _vm.checkDisabled },
            on: { click: _vm.loginSubmit },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("LOGIN")) + "\n    ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }