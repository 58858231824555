<template>
  <section>
    <b-overlay :show="loading" rounded="sm">
      <div v-if="step == 1">
        <form @submit.prevent>
          <div class="form-group">
            <label class="col-form-label">{{ $t("PASSWORD") }}</label>
            <input
              class="form-control inputTransparent"
              v-model="password"
              v-on:keyup.enter="loginSubmit"
              autocomplete="current-password"
              type="password"
              name="password"
              required
            />
          </div>
        </form>
        <button
          :disabled="checkDisabled"
          v-on:click="loginSubmit"
          class="btn mt-2 btn-fill btn-block btn-primary"
        >
          {{ $t("LOGIN") }}
        </button>
      </div>
      <div v-if="step == 2">
        <div class="text-center">
          <div class="caption-title">{{ $t("SMS.SPECIFY_CODE") }}</div>
          <input
            class="mt-2 text-center form-control"
            v-model="sms_code"
            type="text"
            name="code"
            required
            v-on:keyup.enter="authenticateIdentifier"
            :placeholder="$t('SMS.SPECIFY_CODE_PLACEHOLDER')"
          />
          <div class="mt-4">
            <button
              v-on:click="authenticateIdentifier"
              :disabled="disableVerify"
              class="btn btn-fill btn-block btn-primary"
            >
              {{ $t("VERIFY_BTN") }}
            </button>
          </div>
          <div class="mt-2">
            <button
              v-on:click="cancelSubmit"
              class="mt-2 btn btn-block  btn-secondary"
            >
              {{ $t("CANCEL") }}
            </button>
          </div>
        </div>
      </div>
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["loginInfo"],
  data() {
    return {
      step: 1,
      loading: true,
      auth_uuid: "",
      password: "",
      sms_code: "",
      errorMessage: ""
    };
  },
  methods: {    
    reset: function() {
      this.loading = false;
      this.sms_code = "";
      this.authRef = "";
      this.step = 1;
      this.$emit("loading", false);
    },
    cancelSubmit: function() {
      this.reset();
      this.$http.get(this.user.hostname + "/authenticate/cancel/" + this.auth_uuid);
    },
    loginSubmit: async function(event) {
      event.preventDefault();
      this.$emit("loading", true);
      this.loading = true;
      let self = this;
      await this.$store.dispatch("errors/removeValidations");
      this.$http
        .post(this.user.hostname + "/authenticate", {
          email: this.loginInfo.email,
          auth_type: this.loginInfo.auth_type,
          auth_method: this.loginInfo.auth_method,
          password: this.password.trim(),
          client: "web",
        })
        .then(function(result) {
          self.auth_uuid = result.data.auth_uuid;
          self.step = 2;
          self.loading = false;
        })
        .catch(function() {
          self.$emit("loading", false);
          self.loading = false;
        });
    },
    authenticateIdentifier: function() {
      let self = this;
      this.verifying = true;
      this.$http
        .post(this.user.hostname + "/authenticate/verify", {
          auth_uuid: this.auth_uuid,
          code: this.sms_code.trim(),
        })
        .then(function(result) {
          self.$emit("authenticated", result.data.token);
        })
        .catch(function() {
          self.step = 1;
          self.$emit("loading", false);
          self.loading = false;
        });
    },
  },
  computed: {
    disableVerify: function() {
      return this.sms_code.length != 6;
    },
    checkDisabled: function() {
      return this.loginInfo.email == "" || this.password == "";
    },
  },
  mounted: function() {
    this.loginInfo.auth_method = "";
    this.loading = false;
  },
};
</script>
<style></style>
