var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
        _vm.step == 1
          ? _c("div", [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v(_vm._s(_vm.$t("PASSWORD"))),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      staticClass: "form-control inputTransparent",
                      attrs: {
                        autocomplete: "current-password",
                        type: "password",
                        name: "password",
                        required: "",
                      },
                      domProps: { value: _vm.password },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.loginSubmit.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.password = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "btn mt-2 btn-fill btn-block btn-primary",
                  attrs: { disabled: _vm.checkDisabled },
                  on: { click: _vm.loginSubmit },
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("LOGIN")) + "\n      ")]
              ),
            ])
          : _vm._e(),
        _vm.step == 2
          ? _c("div", [
              _c("div", { staticClass: "text-center" }, [
                _c("div", { staticClass: "caption-title" }, [
                  _vm._v(_vm._s(_vm.$t("SMS.SPECIFY_CODE"))),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sms_code,
                      expression: "sms_code",
                    },
                  ],
                  staticClass: "mt-2 text-center form-control",
                  attrs: {
                    type: "text",
                    name: "code",
                    required: "",
                    placeholder: _vm.$t("SMS.SPECIFY_CODE_PLACEHOLDER"),
                  },
                  domProps: { value: _vm.sms_code },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.authenticateIdentifier.apply(null, arguments)
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.sms_code = $event.target.value
                    },
                  },
                }),
                _c("div", { staticClass: "mt-4" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-fill btn-block btn-primary",
                      attrs: { disabled: _vm.disableVerify },
                      on: { click: _vm.authenticateIdentifier },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("VERIFY_BTN")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "mt-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "mt-2 btn btn-block btn-secondary",
                      on: { click: _vm.cancelSubmit },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("CANCEL")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }