<template>
  <section v-if="!user.authenticated">

      <div class="text-center mb-2" v-if="show_logo">        
        <b-img
          fluid
          :src="logoUrl"
          :alt="$t('ORGANISATION.TITLE') + ' ' + $t('NAME')"
          class="login-logo"
        ></b-img>
      </div>

      <div class="box-content" v-if="methods && !checkAuthentication">
        <div v-if="!loading" class="form-group">
          <label class="col-form-label">{{ $t("EMAIL") }}</label>
          <input
            class="form-control inputTransparent"
            v-model="loginInfo.email"
            id="login_email"
            autocomplete="username"
            type="text"
            name="email"
            required
          />
        </div>

        <div v-if="!loading" class="form-group">
          <label class="col-form-label">{{
            $t("AUTH_METHODS.SELECT_METHOD")
          }}</label>

          <b-form-select
            name="auth_type"
            id="auth_options"
            v-model="selectedIndex"
            :options="options"
          ></b-form-select>
        </div>

        <b-form-select
          name="auth_method"
          id="auth_method"
          v-if="currentSelectHasChilds"
          v-model="selectedChildIndex"
          :options="childs_options"
          class="mb-2 mt-2"
        ></b-form-select>

        <AuthPassword
          @authenticated="authenticated"
          @loading="setLoading"
          :loginInfo="loginInfo"
          v-if="loginInfo.auth_type == 'password'"
        ></AuthPassword>

        <AuthPasswordSms
          @authenticated="authenticated"
          @loading="setLoading"
          :loginInfo="loginInfo"
          v-if="loginInfo.auth_type == 'password-sms'"
        ></AuthPasswordSms>

        <AuthSefosCertificate
          @authenticated="authenticated"
          @loading="setLoading"
          :loginInfo="loginInfo"
          v-if="loginInfo.auth_type == 'sefos-certificate'"
        ></AuthSefosCertificate>

        <AuthBankid
          @authenticated="authenticated"
          @loading="setLoading"
          :loginInfo.sync="loginInfo"
          v-if="loginInfo.auth_method == 'bankid-se'"
        ></AuthBankid>

        <AuthFreja
          @authenticated="authenticated"
          @loading="setLoading"
          :loginInfo.sync="loginInfo"
          v-if="
            loginInfo.auth_method == 'freja' ||
            loginInfo.auth_type == 'freja-org'
          "
        ></AuthFreja>

        <AuthNetIDAccess
          @authenticated="authenticated"
          @loading="setLoading"
          :loginInfo="loginInfo"
          v-if="loginInfo.auth_method == 'net-id-access'"
        ></AuthNetIDAccess>

        <AuthNetIDEnterprise
          @authenticated="authenticated"
          @loading="setLoading"
          :loginInfo.sync="loginInfo"
          v-if="loginInfo.auth_method == 'net-id-enterprise'"
        ></AuthNetIDEnterprise>

        <AuthCustom
          @authenticated="authenticated"
          @loading="setLoading"
          :methods="methods"
          :loginInfo.sync="loginInfo"
          v-if="showCustom"
        ></AuthCustom>

        <div v-if="currentSelectHasChilds">
          <button
            :disabled="true"
            v-if="showChildrenSubmitDisabled"
            class="btn btn-fill btn-block mt-2 btn-primary"
          >
            {{ $t("NEXT") }}
          </button>
        </div>
      </div>
      <div class="text-center mt-4" v-if="show_logo">&nbsp;</div>
  </section>
</template>
<script>
import AuthPassword from "@/components/Auth/Password.vue";
import AuthPasswordSms from "@/components/Auth/PasswordSms.vue";
import AuthSefosCertificate from "@/components/Auth/SefosCertificate.vue";
import AuthCustom from "@/components/Auth/Custom.vue";
import AuthFreja from "@/components/Auth/SE/Freja.vue";
import AuthBankid from "@/components/Auth/SE/Bankid.vue";
import AuthNetIDAccess from "@/components/Auth/Siths/NetIDAccess.vue";
import AuthNetIDEnterprise from "@/components/Auth/Siths/NetIDEnterprise.vue";
export default {
  components: {
    AuthPassword,
    AuthPasswordSms,
    AuthSefosCertificate,
    AuthCustom,
    AuthFreja,
    AuthBankid,
    AuthNetIDAccess,
    AuthNetIDEnterprise,
  },
  props: ["login", "auth_uuid"],
  data() {
    return {
      checkAuthentication: false,
      selectedIndex: 0,
      selectedChildIndex: 0,
      childs: [],
      childs_options: [],
      loginInfo: {
        email: "",
        auth_type: "password",
        auth_method: "",
      },
      loading: true,
      methods: null,
      errorMessage: "",
      uniqueIdentifier: "",
      email: "",
      auth_type: "password",
      options: [],
      show_logo: false,
    };
  },
  methods: {
    async init() {
      this.checkAuthenticated();
      if (this.$cookies.isKey("auth_method")) {
        if (this.$cookies.get("auth_method") == null) {
          this.loginInfo.auth_method = "";
        } else {
          this.loginInfo.auth_method = this.$cookies.get("auth_method");
        }
      }
      if (this.$cookies.isKey("auth_type")) {
        this.loginInfo.auth_type = this.$cookies.get("auth_type");
      }
      if (this.$cookies.isKey("email")) {
        this.loginInfo.email = this.$cookies.get("email");
      }
      this.getMethods();
      this.getLogo();
    },
    async processMethods() {
      for (let ix = 0; ix < this.methods.length; ix++) {
        let method = this.methods[ix];
        let methodName = "";
        if (method.auth_type == "custom") {
          methodName = method.custom_name;
        } else {
          methodName = this.$t("AUTH_METHODS." + method.auth_type);
        }
        this.options.push({
          value: ix,
          text: methodName,
        });
        if (method.auth_type == "custom") {
          if (method.auth_method == this.loginInfo.auth_method) {
            this.selectedIndex = ix;
          }
        } else {
          if (method.auth_type == this.loginInfo.auth_type) {
            this.selectedIndex = ix;
          }
        }
      }
      if (this.selectedIndex == 0) {
        this.calculateMethods(0);
      }
    },
    async getMethods() {
      try {
        let result  = await this.$http.get(
          this.user.hostname + "/authenticate/methods/list"
        );
        this.methods = result.data;
        this.loading = false;
        this.processMethods();
      } catch {
        this.loading = false;
      }
    },
    async checkAuthenticated() {      
      try {
        let result = await this.$http.get(this.user.hostname + "/isLoggedin");
        if (result.data.user != undefined && result.data.user != null) {
          this.checkAuthentication = true;
          if (this.$store.state.user.redirect_url != "") {
            let gotoUrl = this.$store.state.user.redirect_url;
            if(gotoUrl.includes("link/view"))
            {
              this.$router.push({ name: "Messages.Inbox" });
            }else{
              this.$cookies.set("redirect_url", "");
              await this.$store.dispatch("user/setRedirectUrl", "");
              window.location.href = gotoUrl;
            }
          } else {
            this.$router.push({ name: "Messages.Inbox" });
          }
        }
      } catch {
        this.loading = false;
      }
    },
    async getLogo() {
      try {
        let result = await this.$http.get(this.user.hostname + "/show-logo");        
        this.show_logo = result.data;
      } catch {
        this.show_logo = false;
      }
    },
    async authenticated(token) {
      if (token != undefined) {
        //this.$cookies.set("token", token, "8h", "", "", true, "None");
        this.$cookies.set("token", token, 0);
        if (this.$cookies.get("redirect_url") == null) {
          if (this.$store.state.user.redirect_url != "") {
            let gotoUrl = this.$store.state.user.redirect_url;
            this.$cookies.set("redirect_url", "", "1s", "", "", true, "None");
            await this.$store.dispatch("user/setRedirectUrl", "");
            window.location.href = gotoUrl;
          } else {
            this.$router.push({ name: "Messages.Inbox" });
          }
        } else {
          let gotoUrl = this.$cookies.get("redirect_url");
          this.$cookies.set("redirect_url", "", "1s", "", "", true, "None");
          await this.$store.dispatch("user/setRedirectUrl", "");
          window.location.href = gotoUrl;
        }
      }
    },
    setLoading: function (val) {
      this.$cookies.set("token", "", "1s", "", "", true, "None");
      this.$cookies.remove("token");
      this.loading = val;
    },
    verifyAuthentication: async function () {      
      if(!this.user.information)
      {
        try {
          let result = await this.$http.get(this.user.hostname + "/authenticate/result/" + this.auth_uuid);
          this.authenticated(result.data.token);
        } catch {
          this.init();
        }
      }
    },
    calculateMethods(newVal) {
      this.childs = [];
      this.childs_options = [];
      let method = this.methods[newVal];
      this.loginInfo.auth_type = method.auth_type;
      if (method.auth_type == "custom") {
        this.loginInfo.auth_method = method.auth_method;
      } else {
        if (method.childs.length == 0) {
          this.loginInfo.auth_method = method.auth_method;
        } else {
          if (method.childs.length > 1) {
            this.childs_options.push({
              value: null,
              text: this.$t("AUTH_METHODS.SELECT_EXTRA_METHOD"),
            });
            this.selectedChildIndex = null;
          }
          let found = false;
          for (let ax = 0; ax < method.childs.length; ax++) {
            let childmethod = method.childs[ax];
            this.childs.push(childmethod);
            this.childs_options.push({
              value: ax,
              text: this.$t("AUTH_METHODS." + childmethod.auth_method),
            });
            if (childmethod.auth_method == this.loginInfo.auth_method) {
              this.selectedChildIndex = ax;
              found = true;
            }
          }
          if (found == false && method.childs.length > 0) {
            this.loginInfo.auth_method = method.childs[0].auth_method;
            this.selectedChildIndex = 0;
          }
        }
      }
    },
  },
  computed: {
    logoUrl() {
      return this.user.hostname + "/logo";
    },
    showCustom() {
      if (this.loginInfo.auth_type == "custom") {
        return true;
      } else {
        if (
          this.loginInfo.auth_type == "se-eid" &&
          this.loginInfo.auth_method == ""
        ) {
          return true;
        }
        if (
          this.loginInfo.auth_type == "siths-card" &&
          this.loginInfo.auth_method == ""
        ) {
          return true;
        }
      }
      return false;
    },
    currentSelectHasChilds() {
      return (
        !this.loading && this.methods[this.selectedIndex].childs.length != 0
      );
    },
    showChildrenSubmitDisabled() {
      return this.currentSelectHasChilds && this.loginInfo.auth_method == null;
    },
  },
  watch: {
    selectedIndex: function (newVal) {
      this.calculateMethods(newVal);
    },
    selectedChildIndex: function (newVal) {
      if (newVal == null) {
        this.loginInfo.auth_method = "";
      } else {
        this.loginInfo.auth_method = this.childs[newVal].auth_method;
      }
    },
    "loginInfo.auth_method": function (newVal) {
      if (newVal == "null") {
        newVal = "";
      }
      this.$cookies.set("auth_method", newVal, Infinity, "", "", true, "None");
    },
    "loginInfo.auth_type": function (newVal) {
      this.$cookies.set("auth_type", newVal, Infinity, "", "", true, "None");
    },
    "loginInfo.email": function (newVal) {
      this.$cookies.set("email", newVal, Infinity, "", "", true, "None");
    },
  },
  mounted: async function () {
    console.log("mounted login");
    if (this.auth_uuid != undefined) {
      this.verifyAuthentication();
    } else {
      this.init();
    }
  },
};
</script>
<style></style>