<template>
  <section>
    <div v-if="!loading">
      
      <b-overlay :show="redirect" rounded="sm">
        <button
          :disabled="checkDisabled"
          v-on:click="loginSubmit"
          class="btn btn-fill mt-2 btn-block btn-primary"
        >
          {{ $t("LOGIN") }}
        </button>
      </b-overlay>
    </div>
    <div class="p-4 text-center" v-if="loading && autoStartToken">
      <div v-if="hintMessage == 'outstandingTransaction'">
        <div v-if="!showOnThisDevice">
          1. {{ $t("BANKID_SE.PROCESS.outstandingTransaction.step1") }}.<br />
          2. {{ $t("BANKID_SE.PROCESS.outstandingTransaction.step2") }}.<br />
          3. {{ $t("BANKID_SE.PROCESS.outstandingTransaction.step3") }}.<br />
          4. {{ $t("BANKID_SE.PROCESS.outstandingTransaction.step4") }}.<br />

          <qrcode-vue
            class="qrcode mt-2 mb-4"
            v-if="autoStartToken != ''"
            :value="qrCode"
            :key="autoStartToken"
            size="200" margin="2"
            level="H"
          ></qrcode-vue>
          
          <a variant="primary" :href="bankidUrlDevice">{{ $t("SAME_DEVICE") }}</a>

        </div>
        <div class="mt-2 mb-2" v-if="showOnThisDevice">
           <a variant="primary" class="btn btn-primary btn-fill text-white" :href="bankidUrlDevice">{{ $t("SAME_DEVICE") }}</a>
          <br />
          <br />
          <a href="#" @click.prevent="otherDevice"> {{ $t("OTHER_DEVICE") }}</a>
        </div>
      </div>

      <div v-if="hintMessage != 'outstandingTransaction'">
        <h5>{{ statusMessage }}</h5>
      </div>

      <button v-on:click="cancelSubmit" class="mt-4 btn btn-secondary">
        {{ $t("CANCEL") }}
      </button>
    </div>
  </section>
</template>
<script>
import mobileDetect from "mobile-device-detect";
import QrcodeVue from "qrcode.vue";
export default {
  components: { QrcodeVue },
  props: ["loginInfo", "method"],
  data() {
    return {
      redirect: false,
      qrCode: "",
      device: mobileDetect,
      loading: true,
      autoStartToken: "",
      statusMessage: "",
      timer: "",
      auth_uuid: "",
      hintMessage: "",
      errorMessage: "",
      showOnThisDevice: false
    };
  },
  methods: {
    otherDevice: function() {
      this.showOnThisDevice = false;
    },
    reset: function() {
      clearTimeout(this.timer);
      this.loading = false;
      this.autoStartToken = "";
      this.qrCode = "";
      this.showOnThisDevice = this.device.isMobile;
      this.$emit("loading", false);
    },
    cancelSubmit: function() {
      event.preventDefault();
      let self = this;
      self.reset();
      this.$http.get(this.user.hostname + "/authenticate/cancel/" + this.auth_uuid);
    },
    loginSubmit: async function(event) {
      event.preventDefault();
      this.loading = true;
      let self = this;
      self.$emit("loading", true);
      await this.$store.dispatch("errors/removeValidations");
      this.$http
        .post(this.user.hostname + "/authenticate", {
          email: this.loginInfo.email,
          auth_type: this.loginInfo.auth_type,
          auth_method: this.loginInfo.auth_method,
          client: "web"
        })
        .then(function(result) {
          if(result.data.redirect == 1)
          {
            self.loading = false;
            self.redirect = true;
            window.location.href = result.data.request_url;
          }else{
            self.statusMessage = "";
            if(result.data.autoStartToken != undefined)
            {   
              self.autoStartToken = result.data.autoStartToken;
            }else{
              self.autoStartToken = result.data.identifier;
            }
            self.qrCode = result.data.qrCode;
            self.auth_uuid = result.data.auth_uuid;
            self.checkStatus();
          }
        })
        .catch(function() {
          self.reset();
        });
    },
    checkStatus: function() {
      let self = this;
      if (this.autoStartToken != "") {
        this.loading = true;
        this.$http
          .get(this.user.hostname + "/authenticate/status/" + this.auth_uuid)
          .then(function(result) {
            if (result.status == 200) {
              clearTimeout(self.timer);
              self.verifyAuthentication();
            }
            if (result.status == 202) {
              if(result.data.hintCode != undefined)
              {
                if(result.data.hintCode == "startFailed")
                {                
                  self.reset();
                }else{
                  self.qrCode = result.data.qrCode;         
                  self.hintMessage = result.data.hintCode;
                  self.statusMessage = self.$t("BANKID_SE.PROCESS." + result.data.hintCode);
                }
              }else{
                if(result.data == "startFailed")
                {                
                  self.reset();
                }else{
                  self.hintMessage = result.data;
                  self.statusMessage = self.$t("BANKID_SE.PROCESS." + result.data);
                }
              }
              self.timer = setTimeout(function() {
                self.checkStatus();
              }, 1500);
            }
          })
          .catch(function(error) {
            self.hintMessage = error.response.data;
            self.reset();
          });
      }
    },
    verifyAuthentication: function() {
      let self = this;
      this.$http
        .get(this.user.hostname + "/authenticate/result/" + this.auth_uuid)
        .then(function(result) {
          self.$emit("authenticated", result.data.token);
        })
        .catch(function() {
          self.reset();
        });
    },
  },
  computed: { 
    bankidUrlDevice() {
      if (this.device.isMobile) {
        return (
          "https://app.bankid.com/?autostarttoken=" +
          this.autoStartToken +
          "&redirect=null"
        );
      } else {
        return this.bankidUrl;
      }
    },
    bankidUrl() {
      return (
        "bankid:///?autostarttoken=" + this.autoStartToken + "&redirect=null"
      );
    },
    checkDisabled: function() {
      return this.email == "";
    },
  },
  mounted: function() {
    this.loading = false;
    this.showOnThisDevice = this.device.isMobile;
  },
};
</script>
<style></style>
