<template>
  <section>
    <b-overlay :show="loading" rounded="sm">
      <button
        :disabled="checkDisabled"
        v-on:click="loginSubmit"
        class="btn btn-fill mt-2 btn-block btn-primary"
      >
        {{ $t("LOGIN") }}
      </button>
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["loginInfo", "methods"],
  data() {
    return {
      loading: true
    };
  },
  methods: {
    loginSubmit: async function() {
      this.loading = true;
      let self = this;
      self.$emit("loading", true);
      await this.$store.dispatch("errors/removeValidations");
      this.$http
        .post(this.user.hostname + "/authenticate", {
          email: this.loginInfo.email,
          auth_type: this.loginInfo.auth_type,
          auth_method: this.auth_method,
          client: "web"
        })
        .then(function(result) {
          window.location.href = result.data.request_url;
        })
        .catch(function() {
          self.$emit("loading", false);
          self.loading = false;
        });
    }
  },
  computed: {
    checkDisabled: function() {
      return this.loginInfo.email == "";
    },
    auth_method: function(){
      if(this.loginInfo.auth_type == "custom")
      {
        return this.loginInfo.auth_method + ""
      }else{
        return "";
      }
    }
  },
  mounted: function() {
    this.loading = false;
     if(this.loginInfo.auth_type != "custom")
      {
        this.loginInfo.auth_method = "";
      }
  },
};
</script>
<style></style>
