var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "col-form-label" }, [
                _vm._v(_vm._s(_vm.$t("PASSWORD"))),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password",
                  },
                ],
                staticClass: "form-control inputTransparent",
                attrs: {
                  autocomplete: "current-password",
                  type: "password",
                  name: "password",
                  required: "",
                },
                domProps: { value: _vm.password },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.loginSubmit.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.password = $event.target.value
                  },
                },
              }),
            ]),
          ]
        ),
        _c(
          "button",
          {
            staticClass: "btn mt-2 btn-fill btn-block btn-primary",
            attrs: { disabled: _vm.checkDisabled },
            on: { click: _vm.loginSubmit },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("LOGIN")) + "\n    ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }