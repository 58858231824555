var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    !_vm.loading
      ? _c(
          "div",
          [
            _c("b-overlay", { attrs: { show: _vm.redirect, rounded: "sm" } }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-fill mt-2 btn-block btn-primary",
                  attrs: { disabled: _vm.checkDisabled },
                  on: { click: _vm.loginSubmit },
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("LOGIN")) + "\n      ")]
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm.loading && _vm.autoStartToken
      ? _c("div", { staticClass: "p-4 text-center" }, [
          _vm.hintMessage == "outstandingTransaction"
            ? _c("div", [
                !_vm.showOnThisDevice
                  ? _c(
                      "div",
                      [
                        _vm._v(
                          "\n        1. " +
                            _vm._s(
                              _vm.$t(
                                "BANKID_SE.PROCESS.outstandingTransaction.step1"
                              )
                            ) +
                            "."
                        ),
                        _c("br"),
                        _vm._v(
                          "\n        2. " +
                            _vm._s(
                              _vm.$t(
                                "BANKID_SE.PROCESS.outstandingTransaction.step2"
                              )
                            ) +
                            "."
                        ),
                        _c("br"),
                        _vm._v(
                          "\n        3. " +
                            _vm._s(
                              _vm.$t(
                                "BANKID_SE.PROCESS.outstandingTransaction.step3"
                              )
                            ) +
                            "."
                        ),
                        _c("br"),
                        _vm._v(
                          "\n        4. " +
                            _vm._s(
                              _vm.$t(
                                "BANKID_SE.PROCESS.outstandingTransaction.step4"
                              )
                            ) +
                            "."
                        ),
                        _c("br"),
                        _vm.autoStartToken != ""
                          ? _c("qrcode-vue", {
                              key: _vm.autoStartToken,
                              staticClass: "qrcode mt-2 mb-4",
                              attrs: {
                                value: _vm.qrCode,
                                size: "200",
                                margin: "2",
                                level: "H",
                              },
                            })
                          : _vm._e(),
                        _c(
                          "a",
                          {
                            attrs: {
                              variant: "primary",
                              href: _vm.bankidUrlDevice,
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("SAME_DEVICE")))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showOnThisDevice
                  ? _c("div", { staticClass: "mt-2 mb-2" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary btn-fill text-white",
                          attrs: {
                            variant: "primary",
                            href: _vm.bankidUrlDevice,
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("SAME_DEVICE")))]
                      ),
                      _c("br"),
                      _c("br"),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.otherDevice.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("OTHER_DEVICE")))]
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.hintMessage != "outstandingTransaction"
            ? _c("div", [_c("h5", [_vm._v(_vm._s(_vm.statusMessage))])])
            : _vm._e(),
          _c(
            "button",
            {
              staticClass: "mt-4 btn btn-secondary",
              on: { click: _vm.cancelSubmit },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("CANCEL")) + "\n    ")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }